import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Site, Page } from "@/types";

type SiteState = {
  sitePage: Page<Site>;
};

const site: Module<SiteState, unknown> = {
  namespaced: true,
  state: () => ({
    sitePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSite: (state) => (siteId: string) =>
      state.sitePage.docs.find((s) => s._id === siteId),
    sitePage: (state) => state.sitePage,
  },
  mutations: {
    ADD_SITE: (state, _site) => {
      let idx = -1;
      state.sitePage.docs.map((s, i) => {
        if (s._id === _site._id) idx = i;
      });
      if (idx === -1) state.sitePage.docs.push(_site);
      else Vue.set(state.sitePage.docs, idx, _site);
    },
    SET_SITE_PAGE: (state, list) => {
      state.sitePage = list;
    },
    REMOVE_SITE(state, site) {
      let idx = -1;
      state.sitePage.docs.map((r, i) => {
        if (r._id === site._id) idx = i;
      });
      if (idx > -1) state.sitePage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchSite(context, params = "") {
      return await api
        .get(`/v1/site${params}`)
        .then((response) => {
          context.commit("ADD_SITE", response.data.site);

          return response.data.site;
        })
        .catch(() => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    fetchSiteList(context, params = "") {
      api
        .get(`/v1/site${params}`)
        .then((response) => {
          context.commit("SET_SITE_PAGE", response.data.sitePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSite(context, payload) {
      return await api
        .post(`/v1/site`, payload, { timeout: 300000 })
        .then((response) => {
          context.commit("ADD_SITE", response.data.site);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Site created",
            },
            { root: true }
          );
          return response.data.site;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateSite(context, data: { id: string; site: Site }) {
      return await api
        .put(`/v1/site/${data.id}`, data.site)
        .then((response) => {
          context.commit("ADD_SITE", response.data.site);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Site updated",
            },
            { root: true }
          );
          return response.data.site;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteSite(context, id) {
      api
        .delete(`/v1/site${id}`)
        .then((response) => {
          context.commit("REMOVE_SITE", response.data.site);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Site deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateFiles(context, payload) {
      return await api
        .post(`/v1/site/update`, payload, { timeout: 300000 })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Changes Applied",
            },
            { root: true }
          );
          return response.data.site;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSiteSettings() {
      return await api
        .get(`/v1/site-setting`)
        .then((response) => {
          return response.data.siteSettings;
        })
        .catch(() => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    async uploadImage(context, { type, payload }) {
      return await api
        .post(`/v1/site/upload-image/${type}`, payload, { timeout: 300000 })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Image Uploaded",
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateImage(context, { type, payload }) {
      return await api
        .put(`/v1/site/upload-image/${type}`, payload, { timeout: 300000 })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Image Uploaded",
            },
            { root: true }
          );
          return response.data.site;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default site;



















































import Vue, { PropType } from "vue";
export default Vue.extend<any, any, any, any>({
  name: "TextControls",
  props: {
    el: {
      type: HTMLElement,
      required: true,
    },
    options: {
      type: Object as PropType<{ bgEditable: boolean; canDelete: boolean }>,
      default: () => ({
        bgEditable: true,
        canDelete: true,
      }),
    },
    colors: {
      type: Object as PropType<{ bgColor: string; fontColor: string }>,
      default: { fontColor: "#fff", bgColor: "#FF000004" },
    },
    itemName: {
      type: String,
    },
  },
  data: () => ({
    fonts: ["Helvetica", "monospace", "sans-serif"],
    fontFamily: "",
    color: "#F48203",
    size: "",
    fontSizes: [6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 46],
    isBold: false,
    isItalic: false,
    align: "",
    bgColor: "",
  }),
  created() {
    this.color = this.colors.fontColor;
    this.bgColor = this.colors.bgColor;
  },
  methods: {
    save() {
      this.el.style.color = this.color || this.el.style.color;
      if (this.options.bgEditable)
        this.el.style.backgroundColor =
          this.bgColor || this.el.style.backgroundColor;
      this.el.style.fontWeight = this.isBold
        ? "bold"
        : this.el.style.fontWeight;
      this.el.style.fontSize = this.size
        ? this.size + "px"
        : this.el.style.fontSize;
      this.el.style.fontFamily = this.fontFamily || this.el.style.fontFamily;
      this.el.style.fontStyle = this.isItalic
        ? "italic"
        : this.el.style.fontStyle;
      this.el.style.textAlign = this.align || this.el.style.textAlign;
      this.$emit("save");
    },
    removeItem() {
      this.el.style.display = "none";
      this.$emit("remove", { item: this.el, name: this.itemName });
    },
  },
});

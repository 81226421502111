


















































import Vue, { PropType } from "vue";
export default Vue.extend<any, any, any, any>({
  name: "FontMultiple",
  props: {
    els: {
      type: Array as PropType<HTMLElement[]>,
      required: true,
    },
    options: {
      type: Object as PropType<{ bgEditable: boolean; canDelete: boolean }>,
      default: () => ({
        bgEditable: true,
        canDelete: true,
      }),
    },
  },
  data: () => ({
    fonts: ["Helvetica", "monospace", "sans-serif"],
    fontFamily: "",
    color: "",
    size: "",
    fontSizes: [6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 46],
    isBold: false,
    isItalic: false,
    align: "",
    bgColor: "",
  }),
  methods: {
    save() {
      this.els.map((el) => {
        el.style.color = this.color || el.style.color;
        if (this.options.bgEditable)
          el.style.backgroundColor = this.bgColor || el.style.backgroundColor;
        el.style.fontWeight = this.isBold ? "bold" : el.style.fontWeight;
        el.style.fontSize = this.size ? this.size + "px" : el.style.fontSize;
        el.style.fontFamily = this.fontFamily || el.style.fontFamily;
        el.style.fontStyle = this.isItalic ? "italic" : el.style.fontStyle;
        el.style.textAlign = this.align || el.style.textAlign;
      });
      this.$emit("save");
    },
  },
});

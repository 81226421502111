





















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import formStoreModule from "@/store/modules/form";
import { Business, Form, Role } from "@/types";

const { mapActions: formActions, mapGetters: formGetters } =
  createNamespacedHelpers("SITE_FORM_LIST");

export default Vue.extend<any, any, any, any>({
  name: "FormSelector",
  created() {
    this.getForms();
  },
  computed: {
    ...formGetters(["formPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "getForms",
  },
  methods: {
    ...formActions(["fetchFormList"]),
    selectForm(form: Form) {
      this.$emit("form-selected", form);
    },
    getForms() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchFormList(`?businessId=${bid}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SITE_FORM_LIST")) {
      this.$store.registerModule("SITE_FORM_LIST", formStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SITE_FORM_LIST");
  },
});

import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Terms, Page } from "@/types";

type TermsState = {
  termsPage: Page<Terms>;
};

const terms: Module<TermsState, unknown> = {
  namespaced: true,
  state: () => ({
    termsPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getTerms: (state) => (termsId: string) =>
      state.termsPage.docs.find(
        (c) => c._id === termsId || c.shop._id === termsId
      ),
  },
  mutations: {
    ADD_TERMS: (state, _terms) => {
      let idx = -1;
      state.termsPage.docs.map((a, i) => {
        if (a._id === _terms._id) idx = i;
      });
      if (idx === -1) state.termsPage.docs.push(_terms);
      else Vue.set(state.termsPage.docs, idx, _terms);
    },
    SET_TERMS_PAGE: (state, list) => {
      state.termsPage = list;
    },
    REMOVE_TERMS(state, _terms) {
      let idx = -1;
      state.termsPage.docs.map((a, i) => {
        if (a._id === _terms._id) idx = i;
      });
      if (idx > -1) state.termsPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchTerms(context, params = "") {
      api
        .get(`/v1/terms${params}`)
        .then((response) => {
          context.commit("ADD_TERMS", response.data.terms);
        })
        .catch(() => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    fetchTermsList(context, params = "") {
      api
        .get(`/v1/terms${params}`)
        .then((response) => {
          context.commit("SET_TERMS_PAGE", response.data.termsPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createTerms(context, payload) {
      return await api
        .post(`/v1/terms`, payload)
        .then((response) => {
          context.commit("ADD_TERMS", response.data.terms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Terms created",
            },
            { root: true }
          );
          return response.data.terms;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateTerms(context, data: { id: string; terms: Terms }) {
      return await api
        .put(`/v1/terms/${data.id}`, data.terms)
        .then((response) => {
          context.commit("ADD_TERMS", response.data.terms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Terms updated",
            },
            { root: true }
          );
          return response.data.terms;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteTerms(context, id) {
      api
        .delete(`/v1/terms${id}`)
        .then((response) => {
          context.commit("REMOVE_TERMS", response.data.terms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Terms deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default terms;

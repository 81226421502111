import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { CancellationPolicy, Page } from "@/types";

type CancellationPolicyState = {
  cancellationPolicyPage: Page<CancellationPolicy>;
};

const cancellationPolicy: Module<CancellationPolicyState, unknown> = {
  namespaced: true,
  state: () => ({
    cancellationPolicyPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getCancellationPolicy: (state) => (cancellationPolicyId: string) =>
      state.cancellationPolicyPage.docs.find(
        (c) =>
          c._id === cancellationPolicyId || c.shop._id === cancellationPolicyId
      ),
  },
  mutations: {
    ADD_CANCELLATION_POLICY: (state, _cancellationPolicy) => {
      let idx = -1;
      state.cancellationPolicyPage.docs.map((cp, i) => {
        if (cp._id === _cancellationPolicy._id) idx = i;
      });
      if (idx === -1)
        state.cancellationPolicyPage.docs.push(_cancellationPolicy);
      else Vue.set(state.cancellationPolicyPage.docs, idx, _cancellationPolicy);
    },
    SET_CANCELLATION_POLICY_PAGE: (state, list) => {
      state.cancellationPolicyPage = list;
    },
    REMOVE_CANCELLATION_POLICY(state, _cancellationPolicy) {
      let idx = -1;
      state.cancellationPolicyPage.docs.map((cp, i) => {
        if (cp._id === _cancellationPolicy._id) idx = i;
      });
      if (idx > -1) state.cancellationPolicyPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchCancellationPolicy(context, params = "") {
      return await api
        .get(`/v1/cancellation-policy${params}`)
        .then((response) => {
          context.commit(
            "ADD_CANCELLATION_POLICY",
            response.data.cancellationPolicy
          );
          return response.data.cancellationPolicy;
        })
        .catch(() => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    fetchCancellationPolicyList(context, params = "") {
      api
        .get(`/v1/cancellation-policy${params}`)
        .then((response) => {
          context.commit(
            "SET_CANCELLATION_POLICY_PAGE",
            response.data.cancellationPolicyPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createCancellationPolicy(context, payload) {
      return await api
        .post(`/v1/cancellation-policy`, payload)
        .then((response) => {
          context.commit(
            "ADD_CANCELLATION_POLICY",
            response.data.cancellationPolicy
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Cancellation Policy created",
            },
            { root: true }
          );
          return response.data.cancellationPolicy;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateCancellationPolicy(
      context,
      data: { id: string; cancellationPolicy: CancellationPolicy }
    ) {
      return await api
        .put(`/v1/cancellation-policy/${data.id}`, data.cancellationPolicy)
        .then((response) => {
          context.commit(
            "ADD_CANCELLATION_POLICY",
            response.data.cancellationPolicy
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Cancellation Policy updated",
            },
            { root: true }
          );
          return response.data.cancellationPolicy;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteCancellationPolicy(context, id) {
      api
        .delete(`/v1/cancellation-policy${id}`)
        .then((response) => {
          context.commit(
            "REMOVE_CANCELLATION_POLICY",
            response.data.cancellationPolicy
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Cancellation Policy deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default cancellationPolicy;
